<template>
  <div class="outNav">
    <div class="triangle" @click="setNavShow" :class="navShow ? 'active' : ''">
      <img
        src="../assets/image/jiantou_xia.png"
        class="bottomImgs"
        :class="navShow ? 'active' : ''"
      />
    </div>

    <div class="nav" :class="navShow ? 'active' : ''">
      <div
        :class="[
          'navLinks',
          item.line ? 'navLinks-line' : '',
          current == item.link ? 'active2' : '',
        ]"
        v-for="(item, index) of navData"
        :key="index"
        @click="changeNav(item.link)"
      >
        <div>
          <img :src="$imgOnlineUrl + '/index/nav/' + item.link + '.png'" />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { Debounce } from "@/utils/debounce";
export default {
  data() {
    return {
      current: 0,
      menu_power: [],
      navData: [
        {
          name: "首页",
          link: "index",
        },
        {
          name: "溯源云",
          link: "traceability",
        },
        {
          name: "设备云",
          link: "gather",
        },
        {
          name: "知识云",
          link: "knowledge",
        },
        {
          name: "农事云",
          link: "task",
        },
        {
          name: "土地管理",
          link: "land",
        },
        {
          name: "产品管理",
          link: "crops",
        },
        {
          name: "农资管理",
          link: "means",
        },

        {
          name: "员工管理",
          link: "personnel",
        },
        {
          name: "供应商管理",
          link: "linkman",
        },
        {
          name: "全景VR",
          link: "vr",
        },
        {
          name: "系统配置",
          link: "farm",
        },
      ],
      navShow: true,
      setTime: null,
    };
  },
  watch: {
    nav: {
      handler(newVal) {
        this.current = newVal;
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState({
      nav: (state) => state.user.nav,
      stateNavShow: (state) => state.app.navShow,
      farmInfo: (state) => state.user.farmInfo,
      farmID: (state) => state.user.farmID,
      web_menu: (state) => state.user.web_menu,
    }),
  },
  created() {
    this.current = sessionStorage.getItem("navIndex") || "index";
    this.navShow = this.stateNavShow;
  },
  mounted() {
    if (JSON.parse(localStorage.getItem("web_menu"))) {
      this.navData = JSON.parse(localStorage.getItem("web_menu"))[0]
        ? JSON.parse(localStorage.getItem("web_menu"))
        : this.web_menu;
    }
  },
  beforeDestroy() {
    if (this.setTime) {
      clearTimeout(this.setTime);
      this.setTime = null;
    }
  },

  methods: {
    ...mapMutations("user", ["SET_NAV", "SET_EQUIPMENT_Name"]),
    ...mapMutations("app", ["SET_NAV_SHOW"]),

    initNav() {
      if (!this.farmInfo.menu_power) return;
      let menu_power = this.farmInfo.menu_power.split(",");
      this.navData.forEach((item, index) => {
        menu_power.forEach((items) => {
          if (item.name == items) {
            this.navData.splice(index, 1);
          }
        });
      });
    },
    goNav(e) {
      this.current = e;
      this.SET_NAV({ nav: e });
      sessionStorage.setItem("navIndex", e);
      sessionStorage.setItem("childTitle", "0");
      this.$router.push({
        name: e,
      });
    },

    changeNav(e) {
      if (
        this.farmID === 63 ||
        this.farmID === 72 ||
        this.farmID === 88 ||
        this.farmID === 75 ||
        this.farmID === 76 ||
        this.farmID === 77
      ) {
        switch (e) {
          case "ai":
            this.current = e;
            this.SET_NAV({ nav: e });
            sessionStorage.setItem("navIndex", e);
            this.$router.push({
              name: "AI",
            });
            break;
          case "monitorSystem":
            this.current = e;
            this.SET_NAV({ nav: e });
            sessionStorage.setItem("navIndex", e);
            sessionStorage.setItem("childTitle", "0");
            sessionStorage.setItem("childTitle3", 810);
            sessionStorage.setItem("equipmentId", 810);
            sessionStorage.setItem("equipmentName", "gather");
            this.SET_EQUIPMENT_Name({ icon: "gather" });
            this.$router.push({
              name: "gather",
              query: { id: 810 },
            });
            break;
          case "meteorological":
            this.current = e;
            this.SET_NAV({ nav: e });
            sessionStorage.setItem("navIndex", e);
            sessionStorage.setItem("childTitle", "0");
            sessionStorage.setItem("childTitle3", 811);
            sessionStorage.setItem("equipmentId", 811);
            sessionStorage.setItem("equipmentName", "meteorological");
            this.SET_EQUIPMENT_Name({ icon: "meteorological" });
            this.$router.push({
              name: "meteorological",
              query: { id: 811 },
            });
            break;
          case "insectMonitoring":
            this.current = e;
            this.SET_NAV({ nav: e });
            sessionStorage.setItem("navIndex", e);
            sessionStorage.setItem("childTitle", "0");
            sessionStorage.setItem("childTitle3", 864);
            sessionStorage.setItem("equipmentId", 864);
            sessionStorage.setItem("equipmentName", "insect");
            this.SET_EQUIPMENT_Name({ icon: "insect" });
            this.$router.push({
              name: "chemical",
              query: { id: 864 },
            });
            break;
          case "seedlingMonitoring":
            this.current = e;
            this.SET_NAV({ nav: e });
            sessionStorage.setItem("navIndex", e);
            sessionStorage.setItem("childTitle", "0");
            sessionStorage.setItem("childTitle3", 874);
            sessionStorage.setItem("equipmentId", 874);
            sessionStorage.setItem("equipmentName", "grow");
            this.SET_EQUIPMENT_Name({ icon: "grow" });
            this.$router.push({
              name: "chemical",
              query: { id: 874 },
            });
            break;
          // 孢子
          case "sporeMonitoring":
            this.current = e;
            this.SET_NAV({ nav: e });
            sessionStorage.setItem("navIndex", e);
            sessionStorage.setItem("childTitle", "0");
            sessionStorage.setItem("childTitle3", 3160);
            sessionStorage.setItem("equipmentId", 3160);
            sessionStorage.setItem("equipmentName", "spore");
            this.SET_EQUIPMENT_Name({ icon: "spore" });
            this.$router.push({
              name: "chemical",
              query: { id: 3160 },
            });
            break;
          case "soilTesting":
            this.current = e;
            this.SET_NAV({ nav: e });
            sessionStorage.setItem("navIndex", e);
            sessionStorage.setItem("childTitle", "0");
            sessionStorage.setItem("childTitle3", 813);
            sessionStorage.setItem("equipmentId", 813);
            sessionStorage.setItem("equipmentName", "soil");
            this.SET_EQUIPMENT_Name({ icon: "soil" });
            this.$router.push({
              name: "chemical",
              query: { id: 813 },
            });
            break;
          // 下面是废弃的
          case "waterQuality":
            this.current = e;
            this.SET_NAV({ nav: e });
            sessionStorage.setItem("navIndex", e);
            sessionStorage.setItem("childTitle", "0");
            sessionStorage.setItem("childTitle3", 812);
            sessionStorage.setItem("equipmentId", 812);
            sessionStorage.setItem("equipmentName", "water");
            this.SET_EQUIPMENT_Name({ icon: "water" });
            this.$router.push({
              name: "chemical",
              query: { id: 812 },
            });

            break;
          case "oxygen":
            this.current = e;
            this.SET_NAV({ nav: e });
            sessionStorage.setItem("navIndex", e);
            sessionStorage.setItem("childTitle", "0");
            sessionStorage.setItem("childTitle3", 1994);
            sessionStorage.setItem("equipmentId", 1994);
            sessionStorage.setItem("equipmentName", "heat");
            this.SET_EQUIPMENT_Name({ icon: "heat" });
            this.$router.push({
              name: "chemical",
              query: { id: 1994 },
            });

            break;
          case "feeder":
            this.current = e;
            this.SET_NAV({ nav: e });
            sessionStorage.setItem("navIndex", e);
            sessionStorage.setItem("childTitle", "0");
            sessionStorage.setItem("childTitle3", 816);
            sessionStorage.setItem("equipmentId", 816);
            sessionStorage.setItem("equipmentName", "feed");
            this.SET_EQUIPMENT_Name({ icon: "feed" });
            this.$router.push({
              name: "chemical",
              query: { id: 816 },
            });

            break;

          default:
            Debounce(this.goNav(e), 1000);
            break;
        }
      } else {
        Debounce(this.goNav(e), 1000);
      }
    },
    setNavShow() {
      this.navShow = !this.navShow;
      this.SET_NAV_SHOW({ navShow: this.navShow });
    },
  },
};
</script>

<style lang="less" scoped>
.triangle {
  display: inline-block;
  height: 60px;
  width: 100px;
  overflow: hidden;
  transform: rotate(135deg);
  position: fixed;
  bottom: -50px;
  left: 46%;
  z-index: 1050;
  cursor: pointer;
  transition: all 0.5s;
  &.active {
    transform: rotate(135deg);
    left: 46%;
    bottom: 34px;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #3e90e5;
    transform-origin: 0 0;
    transform: rotate(45deg);
  }
  .bottomImgs {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 15%;
    transform: rotate(226deg);
    transition: all 0.5s;
    z-index: 1051;
    left: 11%;
    &.active {
      transform: rotate(45deg);
    }
  }
}
.nav {
  min-width: 1500px;
  height: 80px;
  background: url("../assets/image/navbg.png");
  background-size: cover;
  margin: 0 auto;
  position: fixed;
  bottom: -80px;
  left: 50%;
  z-index: 9998;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  padding: 0 220px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  transition: all 0.5s;

  .navLinks {
    width: 120px;
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    cursor: pointer;
    &.active1 {
      background: rgba(0, 252, 211, 0.2);
    }

    &.active2 {
      background: rgba(21, 92, 165, 0.39);
    }
    img {
      width: 42px;
      height: 42px;
    }

    p {
      line-height: 22px;
      color: #fff;
    }
    &::after {
      content: "";
      width: 1px;
      height: 58px;
      background: #125397;
      opacity: 0.35;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
    }
    &.navLinks-line:last-of-type::after {
      background: linear-gradient(
        0deg,
        #3e92e5,
        #3eb7e5,
        #3eb7e5,
        #01b7d8,
        #03d1d1,
        #06f3c7
      ) !important;
    }
    &:last-of-type::after {
      display: none;
    }
  }

  &.active {
    transform: translate(-50%, -80px);
  }
}
</style>
